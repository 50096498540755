import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./css/Home.css"; // Ensure this CSS file exists for styling
import New from "../Components/home/New";
import Assigned from "../Components/home/Assigned";

const Home = () => {
  const [showProfile, setShowProfile] = useState(true);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [assignedStyle, setAssignedStyle] = useState([]);

  const navigate = useNavigate();
  const loginUser = JSON.parse(sessionStorage.getItem("LoginUser"));

  const handleAssignedUserData = (data) => {
    console.log(data, "Assigned User Data");
    setSelectedUserData(data);
    setShowProfile((prev) => !prev);
  };

  return (
    <div className="home-container">
      <div className="heading-section">
        {loginUser?.ROLE === "0" && <New AssignedSylzData={setAssignedStyle} />}
        <Assigned
          SelectUserData={(userData) =>
            navigate(`/dashboard/users/${userData.user_service_id}`)
          }
          AssignedStyle={assignedStyle}
        />
      </div>
    </div>
  );
};

export default Home;
