import React, { useState, useEffect } from "react";
import { Spin, Flex, message, Button, notification } from "antd";
import axios from "axios";
import config from "../../config";

import { Modal } from "antd";
import {} from "@ant-design/icons";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
import Collage from "../Collage/Collage";
//import { display } from "html2canvas/dist/types/css/property-descriptors/display";
const WardrobeCollage = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(true);
  const [disableButton, setdisableButton] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [collageList, setcollageList] = useState([]);
  const [UserImagesData, setUserImagesData] = useState([]);
  const [isEditModalVisibleClose, setisEditModalVisibleClose] = useState(false);
  const [sendfileDataList, setsendfileDataList] = useState([]);
  const [productimagelink, setproductimagelink] = useState([]);
  const [uploadDragImageList, setuploadDragImageList] = useState([]);
  const [axis, setaxis] = useState(0);
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const [uploadDragData, setUploadDragData] = useState(null); // Track the item to be edited
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const fcmToken = sessionStorage.getItem("fcmToken");
  const [editItem, setEditItem] = useState(null);

  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");

  const CollageNotifyBackend = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Stylz",
          NOTI_BODY: "Your lookbook is ready",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: SelectUserData?.STYLIST_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_ID.toString(),
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
      }
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  const getUserImagesData = async (userId) => {
    console.log(SelectUserData, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}getImages/${SelectUserData?.user_id}/${SelectUserData?.gender}`
      );
      // console.log(response, "................");
      if (response?.data?.recordset) {
        // console.log("....");
        const data = response?.data?.recordset;
        console.log(data, ".data...");
        setUserImagesData(data);
        // setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${1}`
      );
      console.log(response, "get collage data");
      if (response?.data?.recordset) {
        setcollageList(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
    setdisableButton(true);
  };
  const getUserCollageData = async (userId) => {
    // console.log(SelectUserData, ".....");
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${0}`
      );
      // console.log(response)
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");

        setcollageList(response.data.recordset);
        setloading(false);
        if (response.data.recordset.length === 0) {
          console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }

    setdisableButton(true);
  };
  useEffect(() => {
    getUserCollageData();
    getUserImagesData();
    // handleTemplateSelection(5)
  }, [SelectUserData]);
  useEffect(() => {
    // This useEffect will run every time COLLAGE_DESCRIPTION or fileToUpload changes
    if (COLLAGE_DESCRIPTION) {
      // Call the upload function after state has been updated
    }
  }, [COLLAGE_DESCRIPTION]);
  const CollageNotifyAdmin = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyAdmin`,
        new URLSearchParams({
          title: `Stylz :- ${SelectUserData.stylist_name} `,
          body:
            SelectUserData.status == 2
              ? `${SelectUserData.first_name} :- Extra lookbook is ready `
              : `${SelectUserData.first_name} :- lookbook is ready `,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const CollageNotify = async () => {
    // console.log(data, "sidjshisil....");
    await CollageNotifyAdmin();
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
          service_id: SelectUserData.SERVICE_SHORT_CODE,
          orderId: SelectUserData.user_service_id,
          amount: '',
          transaction_id: ''
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  // const notificationServicebackend = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${config.baseURL}notificationService`,
  //       new URLSearchParams({
  //         USER_ID: SelectUserData?.user_id.toString(),
  //         NOTI_TITLE: "stylz",
  //         NOTI_BODY: "Your lookbook is ready ",
  //         APP_TYPE: "stylz",
  //         NOTI_STATUS: "0",
  //         STYLIST_ID: SelectUserData?.STYLIST_ID?.toString(),
  //         SERVICE_ID: SelectUserData?.SERVICE_ID.toString(),
  //         USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
  //       }),
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //         },
  //       }
  //     );
  //     console.log(response);
  //     if (response?.status === 200) {
  //       console.log(response.data);
  //       getUnAssignedDetails(LoginUser?.USER_ID);
  //       openNotificationWithIcon("success", "Assigned successfully. ");
  //       setTimeout(() => {}, 10000);
  //       setShowStylz(!showStylz);
  //     }
  //     return response.data;
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error signing in:", error);
  //     // throw error;
  //   }
  // };
  const handleDelete = async (record) => {
    console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        await getUserCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const updateCollage = async () => {
    // try {
    //   const params = {
    //     USER_ID: SelectUserData.user_id.toString(),

    //     COLLAGE: "Stylist completed your outfit",
    //     SERVICE_ID: SelectUserData.SERVICE_ID.toString(),
    //     CUSTOMER_ID: SelectUserData.user_id.toString(),
    //     STYLIST_TOKEN: fcmToken,
    //     CUSTOMER_TOKEN: SelectUserData.fcm_token,
    //   };

    //   const response = await axios.post(
    //     `${config.baseURL}collageImagesList`,
    //     params,
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //       },
    //     }
    //   );
    //   console.log(response, "kdsnknskdnk....out");

    //   if (response?.data) {
    //     console.log(response.data, "kdsnknskdnk....out");
    //     notification.info({
    //       message: "Success",
    //       description: "I have sent the collage to the user.",
    //     });
    //     CollageNotify();
    //     CollageNotifyBackend();
    //     // notificationServicebackend();
    //     await getUserCollageData();
    //     await getUserImagesData();
    //   }

    //   return response.data;
    // } catch (error) {
    //   console.error("Error signing in:", error);
    // }
    CollageNotify();
    CollageNotifyBackend();
    // notificationServicebackend();
    await getUserCollageData();
    await getUserImagesData();
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  function modifyUrls(urls) {
    const baseUrl = "https://skandaqa.stylz.in";

    return urls.map((url) => {
      if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, ""); // Remove the base URL
      }
      return url; // If it doesn't match, return the original URL
    });
  }

  function extractUrls(data) {
    try {
      // Check if data is a string; if so, parse it into an object
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;

      // Extract the 'url' field from each object in the array
      const urls = parsedData.map((item) => {
        if (item.url) {
          return item.url; // Return the URL if it exists
        } else {
          return item; // Return the entire item if URL does not exist
        }
      });

      return urls;
    } catch (error) {
      console.error("Invalid data provided", error);
      return [];
    }
  }
  const UploadSingleColllage = async (path, DESCRIPTION, fileList, imageId) => {
    console.log(uploadDragImageList, "uploadDragImageList.............");
    try {
      const formData = new FormData();

      // Ensure 'path' is valid
      if (path) {
        if (path instanceof File || path instanceof Blob) {
          formData.append("picture", path, path.name); // Append file with its name
        } else {
          console.error("Invalid file object passed as path:", path);
          return;
        }
      }
      console.log(uploadDragImageList, "uploadDragImageList", editItem);
      // Append other data
      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),
          STYLIST_ID: SelectUserData.stylist_id,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_ID: editItem ? editItem.IMAGE_ID : null,
          IMAGE_LIST: fileList ? fileList : uploadDragImageList,

          LINK_LIST: `[${extractUrls(
            fileList ? fileList : uploadDragImageList
          ).join(", ")}]`,
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_ID,
          AXIS: 0,
          SCRAPE_DATA: "",
          COLLAGE_DESCRIPTION: DESCRIPTION,
        })
      );

      console.log("FormData contents:");
      for (const pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      const response = await axios.post(
        `${config.baseURL}createdCollageList`,
        formData
      );

      console.log(response);
      if (response?.status === 200) {
        console.log(response.data);
        message.success("Collage Created successfully");
        await getUserCollageData();
        await getUserImagesData();
        setEditItem(null);
      }

      return response.data;
    } catch (error) {
      console.error("Error uploading collage:", error);
    }
  };

  const uploadCreatedCollage = async (
    file,
    DESCRIPTION,
    fileDataList,
    fileList,
    imageId
  ) => {
    console.log(file, ";;;;;.......................");
    await setCOLLAGE_DESCRIPTION(DESCRIPTION);
    await UploadSingleColllage(
      file,
      DESCRIPTION,
      fileDataList,
      fileList,
      imageId
    );
  };
  const handleTemplateSelection = (template) => {
    setTemplateSelection(template);
  };

  const showEditConfirmation = (item) => {
    setEditItem(item); // Store the item to edit
    // handleModalOk(item);
    setIsEditModalVisible(true); // Show the modal
  };
  //for edit
  const handleModalOk = () => {
    setIsEditModalVisible(false); // Close the modal
    // Proceed with the edit logic
    console.log("Editing item:", editItem);
    // setEditItem(item);
    // Add your edit logic here
  };
  //fot edit
  const handleModalCancel = () => {
    setIsEditModalVisible(false); // Close the modal without editing
    setEditItem(null); // Reset the edit item
  };

  // for upload collage
  const handleSubmitButtonClick = () => {
    // Show confirmation modal when "Upload Collage" is clicked
    setIsModalVisible(true);
  };
  // for upload collage
  const handleModalOk1 = () => {
    setIsModalVisible(false); // Close the modal
    SubmmitCollage(); // Proceed with submitting the collage
  };
  // for upload collage
  const handleModalCancel1 = () => {
    setIsModalVisible(false); // Close the modal without submitting
  };

  // Show the delete confirmation modal
  const showDeleteConfirmation = (item) => {
    setSelectedItem(item); // Set the selected item for deletion
    setIsDeleteModalVisible(true); // Open the delete modal
  };

  // Handle OK button for delete confirmation and proceed with deletion
  const handleDeleteModalOk = () => {
    if (selectedItem) {
      setIsDeleteModalVisible(false); // Close the modal
      console.log("Item deleted:", selectedItem); // Add your delete logic here
      // Proceed with your delete logic (e.g., call delete API or remove item from state)
      handleDelete(selectedItem);
      // setUserShoppingCollageData1((prevData) => prevData.filter((item) => item !== selectedItem));
    }
    setSelectedItem(null); // Reset the selected item after deletion
  };

  // Handle Cancel button for delete confirmation
  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false); // Close the delete modal
    setSelectedItem(null); // Reset the selected item
  };

  const SubmmitCollage = async () => {
    await collageImagesList();
  };
  return (
    <div>
      {loading ? (
        <div className="loading">
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        </div>
      ) : (
        <div>
          {axis === 1 && (
            <div>
              <div>
                <h1 className="heading"> View Collage</h1>
              </div>
              <div className="onlineProduct onlineProductWardrobe">
                {collageList.map((item, index) => (
                  <CollageComponent
                    key={index}
                    count={index + 1}
                    imageUrl={`${item?.IMAGE_NAME}`}
                    // Delete={() => {
                    //   setloading(true);
                    //   setaxis(0);
                    //   handleDelete(item);
                    // }}
                    item={item}
                  />
                ))}
              </div>
            </div>
          )}
          {axis === 0 && (
            <>
              <div style={{ margin: "2% 0" }}>
                {UserImagesData && (
                  <>
                    {((collageList.length < 1 && SelectUserData.status !== 4) ||
                      (collageList.length == 1 && SelectUserData.status == 2) ||
                      editItem) && (
                      <Collage
                        sampleImages={UserImagesData}
                        defaultFrameSize={
                          TemplateSelection ? TemplateSelection : 5
                        }
                        SelectUserData={SelectUserData}
                        // handleSingleCollage={uploadCreatedCollage}
                        uploadDragImageList={(data) => {
                          console.log("data in wardrobe data", data);
                          setuploadDragImageList(data);
                        }}
                        
                        SendinputText={(data) => {
                          setCOLLAGE_DESCRIPTION(data);
                          console.log(data, "shopping ....");
                        }}
                        SendImageLinks={(data) => setproductimagelink(data)}
                        sendfileDataList={(data) => setsendfileDataList(data)}
                        //  uploadCreatedCollage={uploadCreatedCollage}
                        uploadCreatedCollage={(
                          file,
                          description,
                          fileDataList,
                          fileList
                        ) => {
                          const imageId = editItem?.IMAGE_ID || null; // Extract IMAGE_ID from editItem
                          console.log(
                            { editItem, UserImagesData, imageId },
                            "Inside Collage Component"
                          );
                          uploadCreatedCollage(
                            file,
                            description,
                            fileDataList,
                            fileList,
                            imageId
                          );
                        }}
                        // SelectUserData.status!=2 &&
                        editItem={editItem}
                        // editItem={editItem}
                      />
                    )}
                    {editItem && (
                      <div>
                        {" "}
                        <button
                          className="button"
                          onClick={() => {
                            handleModalCancel1();
                            setEditItem(null);
                          }}
                        >
                          {" "}
                          Close{" "}
                        </button>
                      </div>
                    )}
                    {collageList.map((item, index) => (
                      <>
                        {SelectUserData.status != 2 ? (
                          <CollageComponent
                            key={index}
                            count={index + 1}
                            imageUrl={`${item?.IMAGE_NAME}`}
                            // Delete={() => {
                            //   setloading(true);
                            //   setaxis(0);
                            //   handleDelete(item);

                            // }}

                            Delete={() => showDeleteConfirmation(item)}
                            // editItem={() => setEditItem(item)}
                            editItem={() => showEditConfirmation(item)}
                            item={item}
                          />
                        ) : (
                          <CollageComponent
                            key={index}
                            count={index + 1}
                            imageUrl={`${item?.IMAGE_NAME}`}
                            item={item}
                          />
                        )}
                      </>
                    ))}

                    {axis === 0 && (
                      <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
                        <p>{collageList.length === 1 && disableButton}</p>

                        {SelectUserData.status != 2 ? (
                          <Button
                            className="button"
                            disabled={
                              !(collageList.length === 1 && disableButton)
                            }
                            onClick={
                              collageList.length === 1 && disableButton
                                ? collageImagesList
                                : undefined
                            }
                          >
                            Upload Collage
                          </Button>
                        ) : (
                          <Button
                            className="button"
                            disabled={
                              !(collageList.length === 2 && disableButton)
                            }
                            onClick={
                              collageList.length === 2 && disableButton
                                ? collageImagesList
                                : undefined
                            }
                          >
                            Upload Collage
                          </Button>
                        )}
                       
                      </Flex>
                    )}
                  </>
                )}
              </div>
              <Modal
                title="Edit Confirmation"
                open={isEditModalVisibleClose}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Yes"
                cancelText="No"
              >
                <p>Are you sure you want to Close this item?</p>
              </Modal>
              <Modal
                title="Edit Confirmation"
                open={isEditModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Yes"
                cancelText="No"
              >
                <p>Are you sure you want to edit this item?</p>
              </Modal>

              <Modal
                title="Are you sure you want to submit?"
                open={isModalVisible}
                onOk={handleModalOk1} // Proceed with submission when "Yes" is clicked
                onCancel={handleModalCancel1} // Close the modal when "No" is clicked
                okText="Yes"
                cancelText="No"
              >
                <p>Once you submit, it will be sent to the user.</p>
              </Modal>

              <Modal
                title="Delete Confirmation"
                open={isDeleteModalVisible}
                onOk={handleDeleteModalOk}
                onCancel={handleDeleteModalCancel}
                okText="Yes"
                cancelText="No"
              >
                <p>Are you sure you want to delete this item?</p>
              </Modal>
            </>
          )}
          {axios === 0 && (
            <Collage
              key={`template-${TemplateSelection}`}
              UserWardrobeImagesData={UserImagesData}
              defaultFrameSize={TemplateSelection ? TemplateSelection : 5}
              SelectUserData={SelectUserData}
              uploadCreatedCollage={uploadCreatedCollage}
              uploadDragImageList={(data) => setuploadDragImageList(data)}
              SendinputText={(data) => {
                setCOLLAGE_DESCRIPTION(data);
                console.log(data, "wardrobe ....");
              }}
              editItem={editItem}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default WardrobeCollage;
