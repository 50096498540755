import React, { useState, useEffect } from "react";
import CarouselComponent from "../Components/login/Carousel";
import LoginForm from "../Components/login/LoginForm.js";
import "../Components/login/LoginForm.css";
import {requestFCMToken,listenForMessages} from '../Components/firebase/firebaseUtiles';

import { useNavigate } from "react-router-dom";
import logo from "../images/logo.svg";
const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    const fetchFCMToken = async ()=>{
      try {
        const token =await requestFCMToken();
        console.log("fcmToken",token)
        sessionStorage.setItem("fcmToken",token)
 
      }catch (err) {
        console.log("Error getting FCM Token :",err)
      }
    }
   
    

    fetchFCMToken();
  })
 
  useEffect(() => {
   
    const LoginUser =sessionStorage.getItem("LoginUser")? JSON.parse(sessionStorage.getItem("LoginUser")):null
    if (LoginUser) {
      
      navigate("/dashboard");
    }
    
    
    }, []);
  return (
    <>
      <div style={{
            position: "absolute",
            margin: "33px",
      }}>
        {" "}
        <img src={logo} style={{width:'11rem'}} />
      </div>

      <div className="login-page-container">
        {/* <div>
        {" "}
      <img src={logo}/>
        <h2>STYLE</h2>
      </div> */}
        <div className="carousel-side">
          <CarouselComponent />
        </div>
        <div className="form-side">
          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
