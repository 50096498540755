import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import WardrobeCollage from "../scrapinglinks/WardrobeCollage";
import ShoppingCollage from "../scrapinglinks/ShoppingCollage";
import WeddingCollage from "../scrapinglinks/WeddingAsst";
import CapsuleCollage from "../scrapinglinks/CapsuleCollage";
const Scrapinglinks = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [UserScrapinglinksData, setUserScrapinglinksData] = useState([]);

  const Type =
    SelectUserData?.service_category === "Online Shopping"
      ? "Shopping"
      : "Wardrobe";
  console.log(SelectUserData, "..........sdkbuadsbku", Type);
  const getUserScrapinglinksData = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/SHOPPING_ASSISTANT`
      );

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;

        setUserScrapinglinksData(data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
     // throw error;
    }
  };
  useEffect(() => {
    // getUserScrapinglinksData(SelectUserData?.user_id);
  }, []);
  return (
    <div>
      {console.log(
        SelectUserData?.service_category,
        "lksndlknskln,SelectUserData?.service_category"
      )}
      {SelectUserData?.SERVICE_SHORT_CODE === "1" && (
        <ShoppingCollage SelectUserData={sessionStorage.getItem('selectedUser')?JSON.parse(sessionStorage.getItem('selectedUser')):SelectUserData} />
      )}
      {SelectUserData?.SERVICE_SHORT_CODE === "2" && (
        <WardrobeCollage SelectUserData={SelectUserData} />
      )}
      {SelectUserData?.SERVICE_SHORT_CODE === "3" && (
        <WeddingCollage SelectUserData={SelectUserData} />
      )}
      {SelectUserData?.SERVICE_SHORT_CODE === "4" && (
        <CapsuleCollage SelectUserData={SelectUserData} />
      )}
    </div>
  );
};

export default Scrapinglinks;
