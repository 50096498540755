import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { Input, Flex, Button, message, notification } from "antd";
import { Modal } from "antd";
import "./ShoppingCollage.css";
import CollageComponent from "../cards/CollageComponent";
import Collage from "../Collage/Collage";
const ShoppingCollage = ({ SelectUserData }) => {
  const [optionsList, setOptionsList] = useState([]);
  const [UserShoppingCollageData, setUserShoppingCollageData] = useState([]);
  const [linkInputs, setLinkInputs] = useState(Array(8).fill(""));
  const [disableButton, setdisableButton] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [requiredLinks, setRequiredLinks] = useState(0);
  const [dispalycollagecretaion, setdispalycollagecretaion] = useState(false);
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [product, setproduct] = useState([]);
  const [productimagelink, setproductimagelink] = useState([]);
  const [dispalProduct, setdispalProduct] = useState(false);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(false);
  const [axis, setaxis] = useState(0);
  const [sendfileDataList, setsendfileDataList] = useState([]);
  const [displaLinkData, setdisplaLinkData] = useState(true);
  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const [uploadDragData, setUploadDragData] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [UserShoppingCollageData1, setUserShoppingCollageData1] = useState([]);
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [data, setData] = useState([]);
  const [selectedTabName, setSelectedTabName] = useState(null);
  const [error, setError] = useState(null);
  function modifyUrls(urls) {
    const baseUrl = ` ${config.imageUrl}`;
    return urls.map((url) => {
      if (url.startsWith(`${baseUrl}`)) {
        return url.replace(`${baseUrl}`, "");
      }
      return url;
    });
  }
  const CollageNotifyBackend = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Stylz",
          NOTI_BODY: "Your lookbook is ready",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: SelectUserData?.STYLIST_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_SHORT_CODE.toString(),
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response?.data?.message === "success") {
      }
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const showEditConfirmation = (item) => {
    setEditItem(item);
    setIsEditModalVisible(true);
  };
  const handleModalOk = () => {
    setIsEditModalVisible(false);
  };
  const handleModalCancel = () => {
    setIsEditModalVisible(false);
    setEditItem(null);
  };

  const handleSubmitButtonClick = () => {
    setIsModalVisible(true);
  };
  const handleModalOk1 = () => {
    setIsModalVisible(false);
    SubmmitCollage();
  };
  const handleModalCancel1 = () => {
    setIsModalVisible(false);
  };

  const showDeleteConfirmation = (item) => {
    setSelectedItem(item);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalOk = () => {
    if (selectedItem) {
      setIsDeleteModalVisible(false);
      handleDelete(selectedItem);
    }
    setSelectedItem(null);
  };

  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedItem(null);
  };
  const CollageNotifyAdmin = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyAdmin`,
        new URLSearchParams({
          title: `Stylz :- ${SelectUserData.stylist_name} `,
          body:
            SelectUserData.status == 2
              ? `${SelectUserData.first_name} :- Extra Collage is ready `
              : `${SelectUserData.first_name} :- lookbook is ready `,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const CollageUserNotify = async () => {
    await CollageNotifyAdmin();
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
          service_id: SelectUserData.SERVICE_SHORT_CODE,
          orderId: SelectUserData.user_service_id,
          amount: "",
          transaction_id: "",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response?.status === 201) {
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  function extractUrls(data) {
    try {
      const parsedData = typeof data === "string" ? JSON.parse(data) : data;

      const urls = parsedData.map((item) => {
        if (item.url) {
          return item.url;
        } else {
          return item;
        }
      });

      return urls;
    } catch (error) {
      console.error("Invalid data provided", error);
      return [];
    }
  }

  const handleTabChange = (tabName) => {
    setSelectedTabName(tabName);
  };

  const UploadSingleColllage = async (
    path,
    DESCRIPTION,
    fileDataList,
    fileList,
    imageId = null
  ) => {
    try {
      const formData = new FormData();
      if (path) {
        formData.append("picture", path);
      }

      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),
          STYLIST_ID: SelectUserData.stylist_id,
          IMAGE_ID: imageId,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: modifyUrls(fileList),
          LINK_LIST: `[${extractUrls(fileDataList).join(", ")}]`,
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_SHORT_CODE,
          AXIS: 0,
          SCRAPE_DATA: "",
          EVENT_TYPE: selectedTabName,
          JsonResponse: fileDataList,
          COLLAGE_DESCRIPTION: DESCRIPTION,
        })
      );

      const response = await axios.post(
        `${config.baseURL}shoppingCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response?.status === 200) {
        getUserShoppingCollageData();
        setloading(false);
        setdispalycollagecretaion(false);
        setLinkInputs(Array(8).fill(""));
        setproductimagelink([]);
        setproduct([]);
        setEditItem(null);
        message.success(" Collage Created successfully");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_SHORT_CODE
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${1}`
      );

      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get 1 collage data");

        setUserShoppingCollageData(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
    setdisableButton(true);
  };
  const getUserShoppingCollageData = async () => {
    setdisableButton(false);
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_SHORT_CODE
        }/${SelectUserData.USER_SERVICE_ID}/${SelectUserData.stylist_id}/${0}`
      );
      if (response?.data?.recordset) {
        console.log(response.data, "get collage data");

        if (response.data.recordset.length === 0) {
          await getUseronlineCollageData();
        } else {
          setUserShoppingCollageData(response.data.recordset);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }

    setdisableButton(true);
  };

  const getUserShoppingCollageDataTabs = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getWeddingTabs/${SelectUserData.user_id}/WEDDING_ASSISTANCE`
      );
      if (response?.data?.recordset) {
        const optionsList = response.data.recordset.map((item) => {
          const userAnswers = item.USER_ANSWERS
            ? JSON.parse(item.USER_ANSWERS)
            : [];
          return userAnswers.map((answer) => answer.optionText).join(", ");
        });

        setOptionsList(optionsList);
        setData(response.data.recordset);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching report details:", error);
    }
  };

  const getUserCapsuleCollageDataTabs = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getcapsuleTabs/${SelectUserData.user_id}`
      );
      if (response?.data?.recordset) {
        const optionsList = response.data.recordset.map((item) => {
          const userAnswers = item.USER_ANSWERS
            ? JSON.parse(item.USER_ANSWERS)
            : [];
          return userAnswers.map((answer) => answer.optionText).join(", ");
        });

        setOptionsList(optionsList);
        setData(response.data.recordset);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error fetching report details:", error);
    }
  };

  const CreateCollageUseLink = async (urls) => {
    try {
      const params = new URLSearchParams();

      urls.filter(Boolean).forEach((url) => {
        params.append("urls", url);
      });
      const response = await axios.post(`${config.baseURL}scrape`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });

      if (response?.data) {
        const links = response.data.map((data) => data.imageURL);
        setproduct(response.data);
        setproductimagelink(links);
        setloading(false);
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const handleTemplateSelection = (template) => {
    setRequiredLinks(template);
  };
  const handleTemplateSelectionsubmmit = (template) => {
    setTemplateSelection(template);
    if (UserShoppingCollageData.length < 3) {
      setRequiredLinks(template);

      const requiredIndexes = Array.from({ length: template }, (_, i) => i);
      const providedLinksCount = linkInputs
        .slice(0, template)
        .filter((link) => link.trim() !== "").length;

      setHighlightedIndexes([]);
      setValidationError("");
      CreateCollageUseLink(linkInputs);
      setloading(true);
      setdispalProduct(true);
    } else {
      message.error("Only 3 collages can be created. Your limit reached.");
    }
  };
  const CollageNotify = async () => {
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response?.status === 201) {
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const updateCollage = async () => {
    await CollageNotify();
    CollageNotifyBackend();
    CollageUserNotify();
    setaxis(1);
    setEditItem(null);
    await getUseronlineCollageData();
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );

      if (response?.data) {
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  useEffect(() => {
    getUserShoppingCollageData();
    if (SelectUserData?.SERVICE_SHORT_CODE === "3") {
      getUserShoppingCollageDataTabs();
    } else if (SelectUserData?.SERVICE_SHORT_CODE === "4") {
      getUserCapsuleCollageDataTabs();
    }
    handleTemplateSelection(5);
    if (uploadDragData) {
    }
  }, [SelectUserData, loading, uploadDragData]);
  useEffect(() => {}, [UserShoppingCollageData]);

  const uploadCreatedCollage = async (
    file,
    DESCRIPTION,
    fileDataList,
    fileList,
    imageId
  ) => {
    await UploadSingleColllage(
      file,
      DESCRIPTION,
      fileDataList,
      fileList,
      imageId
    );
  };
  const handleDelete = async (record) => {
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      if (response?.status === 200) {
        await getUserShoppingCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const SubmmitCollage = async () => {
    await collageImagesList();
  };
  const CreateCollageButton = () => {
    handleTemplateSelection(TemplateSelection >= 5 ? TemplateSelection : 0);
  };
  const setuploadDragImageList = () => {};
  return (
    <div>
      {(displaLinkData && axis === 0) || axis === 1 ? (
        <>
          {((UserShoppingCollageData.length < 3 &&
            SelectUserData.status !== 4) ||
            (UserShoppingCollageData.length == 3 &&
              SelectUserData.status == 2) ||
            editItem) && (
            <Collage
              sampleImages={product}
              defaultFrameSize={requiredLinks ? requiredLinks : 5}
              SelectUserData={SelectUserData}
              handleSingleCollage={handleTemplateSelectionsubmmit}
              uploadDragImageList={(data) => setuploadDragImageList(data)}
              SendinputText={(data) => {
                setCOLLAGE_DESCRIPTION(data);
              }}
              SendImageLinks={(data) => setproductimagelink(data)}
              sendfileDataList={(data) => setsendfileDataList(data)}
              uploadCreatedCollage={(
                file,
                description,
                fileDataList,
                fileList
              ) => {
                const imageId = editItem?.IMAGE_ID || null;
                uploadCreatedCollage(
                  file,
                  description,
                  fileDataList,
                  fileList,
                  imageId
                );
              }}
              onTabChange={handleTabChange}
              optionsList={optionsList}
              editItem={editItem}
            />
          )}{" "}
          {console.log(UserShoppingCollageData, "UserShoppingCollageData len")}
          {editItem && (
            <div>
              {" "}
              <button
                className="button"
                onClick={() => {
                  handleModalCancel1();
                  setEditItem(null);
                }}
              >
                {" "}
                Close{" "}
              </button>
            </div>
          )}
          {SelectUserData.status === 4 && (
            <>
              <p style={{ textAlignLast: "center" }}>User Service is Closed</p>
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="shoppongCollageContainer">
        {UserShoppingCollageData.length > 0 && (
          <div className="userProfileMain" style={{ marginBottom: "10pxli" }}>
            <h1 className="heading"> Created Collage List</h1>
          </div>
        )}

        <Modal
          title="Edit Confirmation"
          open={isEditModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to edit this item?</p>
        </Modal>

        <Modal
          title="Are you sure you want to submit?"
          open={isModalVisible}
          onOk={handleModalOk1}
          onCancel={handleModalCancel1}
          okText="Yes"
          cancelText="No"
        >
          <p>Once you submit, it will be sent to the user.</p>
        </Modal>

        <Modal
          title="Delete Confirmation"
          open={isDeleteModalVisible}
          onOk={handleDeleteModalOk}
          onCancel={handleDeleteModalCancel}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to delete this item?</p>
        </Modal>

        {axis === 0  && SelectUserData?.status !== 4 ? (
          <Flex direction="row" className="Collagelist" wrap="nowrap">
            {UserShoppingCollageData &&
              UserShoppingCollageData.map((item, index) => (
                <>
                  {SelectUserData.status != 2 ? (
                    <CollageComponent
                      key={index}
                      count={index + 1}
                      imageUrl={item?.IMAGE_NAME}
                      Delete={() => showDeleteConfirmation(item)}
                      editItem={() => showEditConfirmation(item)}
                      item={item}
                    />
                  ) : (
                    <CollageComponent
                      key={index}
                      count={index + 1}
                      imageUrl={item?.IMAGE_NAME}
                      item={item}
                    />
                  )}
                </>
              ))}
          </Flex>
        ) : (
          <div className="onlineProduct">
            {UserShoppingCollageData.map((item, index) => (
              <CollageComponent
                key={index}
                count={index + 1}
                imageUrl={item?.IMAGE_NAME}
                editItem={() => showEditConfirmation(item)}
                item={item}
              />
            ))}
          </div>
        )}
        {SelectUserData?.status === 4 ? (
          <></>
        ) : (
          <>
            {axis === 0 && (
              <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
                {SelectUserData.status == 2 ? (
                  <Button
                    className="button"
                    disabled={
                      !(UserShoppingCollageData.length == 4 && disableButton)
                    }
                    onClick={
                      UserShoppingCollageData.length === 4 && disableButton
                        ? handleSubmitButtonClick
                        : undefined
                    }
                  >
                    Upload Collage
                  </Button>
                ) : (
                  <Button
                    className="button"
                    disabled={!(UserShoppingCollageData.length == 3)}
                    onClick={
                      UserShoppingCollageData.length === 3
                        ? handleSubmitButtonClick
                        : undefined
                    }
                  >
                    Upload Collage
                  </Button>
                )}
              </Flex>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShoppingCollage;
