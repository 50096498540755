import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import "./css/Accessories.css";
import CollageShoppingComponent from "../Components/cards/CollageShoppingComponent";
const ScrapingLinkData = ({ SelectUserData }) => {
  // State to store image URLs
  const [imageUrls, setImageUrls] = useState([]);

  // Fetch user ScrapingLinkData data
  const getUserScrapingLinkDataData = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${
          SelectUserData.user_id
        }/${"3"}/${SelectUserData.SERVICE_SHORT_CODE}`
      );

      if (response?.data) {
        const fetchedData = response.data[0];
        console.log(fetchedData, "...........");
        const selectedImagesList = JSON.parse(
          JSON.parse(fetchedData?.SELECTED_IMAGES_DATA)
        );
        console.log(selectedImagesList, "...........");
        if (selectedImagesList) {
          sessionStorage.setItem(
            "linkSelectedData",
            fetchedData?.SELECTED_IMAGES_DATA
          );
          setImageUrls(selectedImagesList);
          // setImageUrls(selectedImagesList.map((item) => item.imageURL));
        } else {
          setImageUrls([]);
        }
      } else {
        setImageUrls([]);
      }
    } catch (error) {
      console.error("Error fetching ScrapingLinkData data:", error);
      setImageUrls([]);
    }
  };

  useEffect(() => {
    getUserScrapingLinkDataData();
  }, []);

  return (
    <div className="ScrapingLinkData-container">
      {/* Display Images */}
      {imageUrls.length > 0 ? (
        <div className="subcategory-images">
          {imageUrls.map((imageUrl, index) => (
            // <div key={index} className="subcategory-item">
            //   <img src={`${config.imageUrl}/${imageUrl}`} alt={`Scraped Image ${index + 1}`} />
            // </div>
            // <CollageShoppingComponent
            //     // brand={imageUrl.brand}
            //     imageURL={imageUrl.imageURL}
            //     // name={imageUrl.name}
            //     // price={imageUrl.price}
            //     item={imageUrl}
            //     style={{
            //       width: "100%",
            //       height: "auto",
            //     }}/>
            <div
              className="CollageShoppingComponent"
              onDragStart={(e) => {
                // Set only the image URL in the data transfer
                // e.dataTransfer.setData("text/plain",item);
                const imageData = JSON.stringify(imageUrl);
                e.dataTransfer.setData("application/json", imageData);
              }}
            >
              <img
                className="Scrapiinglinkdataimage"
                src={`${config.imageUrl}/${imageUrl.imageURL}`}
                alt={imageUrl.name}
                style={{ width: "7.6rem" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="no-data-message">
          <p>No data available</p>
        </div>
      )}
    </div>
  );
};

export default ScrapingLinkData;
