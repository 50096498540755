import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home'
import Notification from '../pages/Notification'
import Servicehistory from '../pages/Servicehistory';
import Changepassword from '../pages/Changepassword';
import Profile from '../pages/Profile';
import UserProfile from '../pages/UserProfile';
import Manageuser from '../pages/Manageuser';
// import History from '../pages/History'
const LayoutRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="Notifications" element={<Notification />} />
      <Route path="ServiceHistory" element={<Servicehistory />} />
      <Route path="Manageuser" element={<Manageuser />} />
      <Route path="ChangePassword" element={<Changepassword />} />
      <Route path="Profile" element={<Profile />} />
      <Route path="Users/:userId" element={<UserProfile />}/>
       
      
    </Routes>
  );
};
 
export default LayoutRoutes;