import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, notification } from "antd";
import config from "../../config";
import "../../Components/Collage/collage.css";

const SelectedAccessories = ({ SelectUserData }) => {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [subcategoriesInSelectedCategory, setSubcategoriesInSelectedCategory] =
    useState([]);
  const [selectedOutfitCategory, setSelectedOutfitCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const getPreviouslySelectedItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${
          SelectUserData.user_id
        }/${1}/${SelectUserData.SERVICE_SHORT_CODE}`
      );
      if (response?.data && response.data.length > 0) {
        const data = response.data[0];
        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );

        if (selectedImagesList && selectedImagesList.length > 0) {
          setSelectedItems(selectedImagesList);
          const categories = [
            ...new Set(selectedImagesList.map((item) => item.OUTFIT_CATEGORY)),
          ];
          setUniqueCategories(categories);

          // Set default category to the first one
          setSelectedOutfitCategory(categories[0]);
        } else {
          // notification.info({
          //   message: 'Info',
          //   description: 'No selected images found.',
          // });
          setSelectedItems([]);
        }
      } else {
        // notification.info({
        //   message: 'Info',
        //   description: 'No records found.',
        // });
      }
    } catch (error) {
      console.error("Error fetching previously selected items:", error);
      // notification.error({
      //   message: 'Error',
      //   description: 'Failed to load previously selected items.',
      // });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPreviouslySelectedItems();
  }, []);

  useEffect(() => {
    if (selectedOutfitCategory) {
      const filteredSubcategories = [
        ...new Set(
          selectedItems
            .filter((item) => item.OUTFIT_CATEGORY === selectedOutfitCategory)
            .map((item) => item.OUTFIT_TEXT)
        ),
      ];
      setSubcategoriesInSelectedCategory(filteredSubcategories);
      setSelectedSubcategory(filteredSubcategories[0]); // Reset selected subcategory
    }
  }, [selectedOutfitCategory, selectedItems]);

  const handleCategorySelect = (category) => {
    setSelectedOutfitCategory(category);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleItemSelect = (item) => {
    // Logic to handle item selection (e.g., add/remove items)
    // You can implement your selection logic here.
  };
  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <div>
      <Spin spinning={loading} tip="Loading...">
        <div className="user-wardrobe scroll">
          {uniqueCategories.map((category, index) => (
            <button
              key={index}
              className={`button ${
                selectedOutfitCategory === category ? "selected" : ""
              }`}
              onClick={() => handleCategorySelect(category)}
            >
              {capitalizeFirstLetter(category)}
            </button>
          ))}
        </div>

        {subcategoriesInSelectedCategory.length > 0 && (
          <div className="user-wardrobe scroll">
            {subcategoriesInSelectedCategory.map((subcategory, index) => (
              <button
                key={index}
                className={`button ${
                  selectedSubcategory === subcategory ? "selected" : ""
                }`}
                onClick={() => handleSubcategorySelect(subcategory)}
              >
                {capitalizeFirstLetter(subcategory)}
              </button>
            ))}
          </div>
        )}

        {/* <div className={`${selectedItems.length > 0}&&"subcategory-images" `}> */}
        <div className="subcategory-images">
          {selectedItems.length > 0 ? (
            selectedItems
              .filter(
                (item) =>
                  (!selectedOutfitCategory ||
                    item.OUTFIT_CATEGORY === selectedOutfitCategory) &&
                  (!selectedSubcategory ||
                    item.OUTFIT_TEXT === selectedSubcategory)
              )
              .map((item) => (
                <div
                  key={item.ID}
                  className={`subcategory-item ${
                    selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleItemSelect(item)}
                  style={{
                    border: selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "1px solid #ccc"
                      : "1px solid #ccc",
                  }}
                  onDragStart={(e) => {
                    e.dataTransfer.setData(
                      "application/json",
                      `${config.imageUrl}${item.OUTFIT_IMAGE}`
                    );
                  }}
                >
                  <img
                    src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
                    alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
                  />
                  <p>{item.OUTFIT_TEXT}</p>
                </div>
              ))
          ) : (
            <p className="AccessoriesText-width">
              No selected images available. Please select items in the
              Accessories Page.
            </p>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SelectedAccessories;
