import React, { useState, useEffect } from "react";
import { Input, Spin } from "antd";
import axios from "axios";
import config from "../../config";
import CurrentServicesCard from "../cards/CurrentServicesCard";
import UserDisplayCard from "../cards/UserDisplayCard";

const { Search } = Input;

function Assigned({ SelectUserData, AssignedStyle }) {
  const [search, setSearch] = useState("");
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setloading] = useState(true);

  const getCustomerDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}${
          LoginUser.ROLE === "0" ? "getCustomerDetails" : "getCustomerDetails1"
        }/${userId}`
      );

      if (response?.data?.recordset) {
        const data = response?.data?.recordset?.filter(
          (record) => record.status !== 0
        );

        setUserData(data);
        setFilteredData(data);
        setloading(false);
      }
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
    }
  };

  useEffect(() => {
    getCustomerDetails(LoginUser?.USER_ID);
  }, [AssignedStyle]);

  // Filter and sort data by search input
  useEffect(() => {
    const filtered = UseData.filter((user) => {
      const firstName = user?.first_name || "No Name";
      return firstName.toLowerCase().includes(search.toLowerCase());
    });

    // Sort filtered data: Entries with "No Name" last
    const sortedData = filtered.sort((a, b) => {
      const nameA = a?.first_name || "No Name";
      const nameB = b?.first_name || "No Name";
      if (nameA === "No Name") return 1; // Push "No Name" to the end
      if (nameB === "No Name") return -1; // Push "No Name" to the end
      return nameA.localeCompare(nameB); // Sort alphabetically
    });

    setFilteredData(sortedData);
  }, [search]);

  const onClick = (data) => {
    SelectUserData(data);
    sessionStorage.setItem("selectedUser", JSON.stringify(data));
  };

  return (
    <div className="heading-container">
      {LoginUser.ROLE === "0" ? (
        <h1 className="heading">Stylist Assigned</h1>
      ) : (
        <h1
          className="heading"
          style={{ textAlign: "left", paddingLeft: "2%" }}
        >
          Current Services
        </h1>
      )}
      {/* Search Functionality */}
      <div
        className="filter-container"
        style={{
          margin: "0.5rem 2% -0.5rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Search
          placeholder="Search by name"
          onChange={(e) => setSearch(e.target.value)}
          // style={{ width: 200 }}
          allowClear
        />
      </div>
      <div
        className="body-section"
        style={{
          marginTop: "0",
        }}
      >
        {!loading ? (
          <>
            {filteredData.length > 0 ? (
              filteredData.map((user, index) => (
                // LoginUser.ROLE === "0" ? (
                <UserDisplayCard
                  key={`${user.user_id}_${index}`}
                  image={user.user_photo}
                  name={user.first_name || "No Name"}
                  dob={user.age}
                  category={user.service_title}
                  user={user}
                  stylistName={user.stylist_name}
                  buttonText={"Continue"}
                  background={`${
                    user.SERVICE_SHORT_CODE === "1"
                      ? "#FBF1E9"
                      : user.SERVICE_SHORT_CODE === "2"
                      ? "#ffffbc"
                      : user.SERVICE_SHORT_CODE === "3"
                      ? "#fee4cb"
                      : user.SERVICE_SHORT_CODE === "4"
                      ? "#EBF6FF"
                      : "#ffffff"
                  }`}
                  onClick={() => onClick(user)}
                />
              ))
            ) : (
              <div className="nouserassigned">
                <h3>No user assigned.</h3>
              </div>
            )}
          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Assigned;
