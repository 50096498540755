import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button, Input, Upload, message } from "antd";
import {
  MessageOutlined,
  UploadOutlined,
  CameraOutlined,
  SendOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Profile from "../../images/profile.jpg";
import config from "../../config";
import "./chatboat.css";
import moment from "moment";

const { TextArea } = Input;

const Chatbot = ({ onButtonClick, setBack, SelectUserData, onClosebutton }) => {
  const [visible, setVisible] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [fileList, setFileList] = useState([]);

  const fcmToken = sessionStorage.getItem("fcmToken");
  const [chatHistory, setChatHistory] = useState([]);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    const container = chatContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const onClose = () => {
    setVisible(false);
    onClosebutton(false);
  };

  const Type =
    SelectUserData?.service_category === "Online Shopping"
      ? "Shopping"
      : "Wardrobe";
  function extractBaseUrl(url) {
    const parts = url.split("https://").filter(Boolean);

    return `https://${parts[parts.length - 1]}`;
  }
  const fetchChatHistory = () => {
    if (SelectUserData?.user_service_id) {
      axios
        .get(`${config.baseURL}getChat/${SelectUserData.user_service_id}`)
        .then((response) => {
          console.log(response, "response...........");
          setChatHistory(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the chat history!", error);
        });
    }
  };

  useEffect(() => {
    let intervalId;
    if (visible && SelectUserData?.user_service_id) {
      fetchChatHistory();
      intervalId = setInterval(fetchChatHistory, 2000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [visible, SelectUserData]);

  const ChatNotify = async (chatText) => {
    try {
      console.log("service_id type:", typeof SelectUserData.service_id);
      console.log("user_service_id type:", typeof SelectUserData.user_service_id);  // Print the type of user_service_id
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Chat",
          body: chatText,
          service_id: SelectUserData.SERVICE_SHORT_CODE,
          orderId: SelectUserData.user_service_id,
          amount: '',
          transaction_id: ''
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    let chatText = "";
    let type = "";
    let images = "";
    console.log(fileList.length, "fileList.length");
    if (fileList.length > 0) {
      formData.append("picture", fileList[0].originFileObj);
      chatText = "IMAGES";
      type = "IMAGE";
      images = "IMAGES";
    } else {
      chatText = userInput;
      type = "TEXT";
      images = "";
    }

    const data = {
      CHAT_ID: 0,
      USER_ID: SelectUserData?.id,
      SERVICE_ID: SelectUserData?.service_id,
      CHAT: chatText,
      TYPE: type,
      USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
      STYLIST_ID: SelectUserData?.stylist_id,
      IMAGES: images,
    };

    formData.append("data", JSON.stringify(data));

    try {
      const response = await axios.post(
        `${config.baseURL}updateChatting`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      message.success("Message sent successfully!");
      ChatNotify(chatText);
      setChatHistory([...chatHistory, response.data]);
      setUserInput("");
      setFileList([]);
    } catch (error) {
      console.error("There was an error sending the message!", error);
      message.error("Failed to send the message.");
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const getTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    const formattedHours = hours.toString().padStart(2, "0");

    return `${formattedHours}:${minutes} ${period}`;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <div>
      <Drawer
        title={
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`${
                  SelectUserData.user_photo
                    ? `${config.imageUrl}${SelectUserData.user_photo}`
                    : Profile
                }`}
                alt="User"
                className="Chat-header-img"
              />
              <span style={{ flex: 1 }}>{SelectUserData?.first_name}</span>
            </div>
            <div></div>
          </>
        }
        placement="right"
        onClose={() => onClose(false)}
        visible={visible}
        width={450}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div
            ref={chatContainerRef}
            className="chat-container"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              overflowY: "auto",
              height: "500px",
            }}
          >
            {chatHistory
              .slice()
              .reverse()
              .map((chat, index) => (
                <div
                  key={index}
                  className={chat.CUSTOMER_ID ? "their-message" : "my-message"}
                >
                  {chat.TYPE === "IMAGE" && (
                    <img
                      src={extractBaseUrl(`${config.imageUrl}${chat.IMAGES}`)}
                      alt="Chat"
                    />
                  )}
                  <div className="Chat-Ptag">
                    {chat.TYPE !== "IMAGE" && <p>{chat.CHAT}</p>}
                    <span style={{ fontSize: "0.8em", color: "#888" }}>
                      {chat.CREATED_DATE
                        ? getTimeFromTimestamp(chat.CREATED_DATE)
                        : "loading..."}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="Chat-Bottom-content">
            <Upload
              fileList={fileList}
              onChange={handleFileChange}
              beforeUpload={() => false}
              multiple={true}
            >
              <Button icon={<CameraOutlined />}></Button>
            </Upload>
            <Input
              rows={2}
              value={userInput}
              onChange={handleInputChange}
              placeholder="Type your message here..."
              onKeyDown={handleKeyDown}
            />

            {/* Submit button */}
            <Button
              type="primary"
              disabled={(userInput.length < 1) === (fileList.length === 0)}
              onClick={handleSubmit}
              style={{ marginTop: 10 }}
            >
              <SendOutlined />
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Chatbot;
