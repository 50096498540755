import React, { useState, useEffect } from "react";
import axios from "axios";
import { LinkOutlined } from "@ant-design/icons";
import { Input, Button, Spin, notification } from "antd";
import config from "../../config";
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
import "./ShoppingCollage.css";
import { load } from "cheerio";
 
const Links = ({ SelectUserData }) => {
  const [linkInputs, setLinkInputs] = useState(
    Array.from({ length: 10 }, () => ({ siteUrl: "", imageUrl: "" }))
  );
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [product, setProduct] = useState([]);
  const [productImageLink, setProductImageLink] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBatch, setLoadingBatch] = useState(false);
  const [sampleImages, setSampleImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesData, setSelectedImagesData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [preLinks, setPreLinks] = useState([]);
 
  const fetchInsertedData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${SelectUserData.user_id}/3/${SelectUserData.SERVICE_SHORT_CODE}`
      );
 
      if (response?.data && response.data.length > 0) {
        const data = response.data[0];
 
        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );
        const sampleImagesList = JSON.parse(JSON.parse(data.SOURCE_CONTENT));
 
        setSampleImages(sampleImagesList);
        setSelectedImages(selectedImagesList.map((item) => item.imageURL));
        setSelectedImagesData(selectedImagesList);
        setUploadedImages(selectedImagesList.map((item) => item.imageURL));
      } else {
        console.warn("No data found for the given user ID.");
        setSampleImages([]);
        setSelectedImages([]);
        setSelectedImagesData([]);
        setUploadedImages([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      notification.error({
        message: "Fetch Error",
        description: "There was an error fetching the inserted data.",
      });
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    fetchInsertedData();
  }, []);
 
  const handleLinkChange = (index, field, e) => {
    const newLinkInputs = [...linkInputs];
    newLinkInputs[index] = {
      ...newLinkInputs[index],
      [field]: e.target.value,
    };
    setLinkInputs(newLinkInputs);
  };
 
  const RemoveBackground = async (items) => {
    setLoading(true);
    try {
      const response = await axios.post(`${config.baseURL}scrapeMyntra`, [
        items,
      ]);
      if (response?.data) {
        setProduct((prev) => [...prev, ...response.data]);
        setProductImageLink((prev) => [...prev, ...response.data?.[0]?.url]);
        setSampleImages((prevSampleImages) => [
          ...prevSampleImages,
          ...response.data,
        ]);
      }
    } catch (err) {
      console.error("Error during scraping:", err);
    } finally {
      setLoading(false);
    }
  };
 
  const runScraper = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
      });
 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
 
      const data = await response.text();
      const $ = load(data);
 
      let productData = null;
      $('script[type="application/ld+json"]').each((i, script) => {
        const jsonData = JSON.parse($(script).html());
 
        if (jsonData["@type"] === "Product") {
          productData = {
            name: jsonData.name,
            imageURL: jsonData.image,
            url: jsonData.offers?.url,
            price: jsonData.offers?.price,
            brand: "Myntra",
          };
        }
      });
 
      if (Array.isArray([productData])) {
        await RemoveBackground(productData);
      } else {
        console.error("productData is not iterable:", productData);
      }
    } catch (error) {
      console.error("Error fetching the URL: ", error);
    } finally {
      setLoading(false);
    }
  };
 
  const CreateCollageUseLink = async () => {
    setHighlightedIndexes([]);
    setLoading(true);
 
    try {
      const filledLinks = linkInputs.filter((link) => link.siteUrl);
 
      setPreLinks((prev) => [...prev, filledLinks]);
 
      if (filledLinks.length < 1) {
        notification.error({
          message: "Validation Error",
          description: "Please enter at least one valid link.",
        });
 
        setHighlightedIndexes(
          linkInputs
            .map((link, index) =>
              link.siteUrl === "" || link.imageUrl === "" ? index : null
            )
            .filter(Number.isInteger)
        );
        return;
      }
 
      const myntraLinks = filledLinks.filter((link) =>
        link.siteUrl.toLowerCase().includes("myntra")
      );
 
      const otherLinks = filledLinks.filter(
        (link) => !link.siteUrl.toLowerCase().includes("myntra")
      );
 
      const processBatch = async (batch) => {
        const response = await axios.post(
          `${config.baseURL}scrape`,
          { urls: batch },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
 
        if (response?.data) {
          const links = await response.data.map((data) => data.imageURL);
          setProduct((prev) => [...prev, ...response.data]);
          setProductImageLink((prev) => [...prev, ...links]);
          setSampleImages((prevSampleImages) => [
            ...prevSampleImages,
            ...response.data,
          ]);
        }
      };
 
      for (let i = 0; i < myntraLinks.length; i++) {
        const myntraBatch = [myntraLinks[i]];
        await runScraper(myntraBatch[0].siteUrl);
      }
 
      for (let i = 0; i < otherLinks.length; i++) {
        const batch = [otherLinks[i]];
        setLoadingBatch(true);
        await processBatch(batch);
        setLoadingBatch(false);
      }
 
      setLinkInputs(
        Array.from({ length: 10 }, () => ({ siteUrl: "", imageUrl: "" }))
      );
      setHighlightedIndexes([]);
    } catch (error) {
      console.error("Error creating collage:", error);
    } finally {
      setLoading(false);
    }
  };
 
  const toggleImageSelection = (imageURL) => {
    const image = imageURL.imageURL;
 
    const newSelectedImages = selectedImages.includes(image)
      ? selectedImages.filter((url) => url !== image)
      : [...selectedImages, image];
    setSelectedImages(newSelectedImages);
 
    const newSelectedImagesData = selectedImagesData.some(
      (data) => data.imageURL === image
    )
      ? selectedImagesData.filter((data) => data.imageURL !== image)
      : [...selectedImagesData, imageURL];
    setSelectedImagesData(newSelectedImagesData);
  };
 
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (selectedImagesData.length < 2) {
        notification.error({
          message: "Minimum",
          description: "Please select at least two images to upload.",
        });
        return;
      }
 
      const formData = new FormData();
      formData.append("CUSTOMER_ID", SelectUserData.user_id);
      formData.append("STYLIST_ID", SelectUserData.stylist_id);
      formData.append("SERVICE_ID", SelectUserData.SERVICE_SHORT_CODE);
      formData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
      formData.append("LINK_LIST", JSON.stringify(preLinks));
 
      selectedImages.forEach((image) => {
        formData.append("IMAGE_LIST", image);
      });
      formData.append("SOURCE_CONTENT", JSON.stringify(sampleImages));
      formData.append(
        "SELECTED_IMAGES_DATA",
        JSON.stringify(selectedImagesData)
      );
      formData.append("type", "3");
 
      const uploadResponse = await axios.post(
        `${config.baseURL}websiteSourceContent`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
 
      if (uploadResponse.status === 200) {
        notification.success({
          message: "Upload Successful",
          description: "Images have been uploaded successfully.",
        });
 
        setUploadedImages((prev) => [...prev, ...selectedImages]);
        setSelectedImages([]);
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Failed to upload images. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      notification.error({
        message: "Upload Error",
        description: "There was an error uploading images.",
      });
    } finally {
      setLoading(false);
    }
  };
 
  return (
    <>
      <h1 className="heading">Extracting Links</h1>
      <div className="Shopping-Collage-Wrapper">
        <p style={{ margin: "2% 2% 1% 2%" }}>
          Paste the links here to generate outfit images
        </p>
        <div className="Shopping-Collage-Grid">
          {linkInputs.map((link, index) => (
            <div
              key={index}
              className={`Shopping-Collage-Subcontent ${
                highlightedIndexes.includes(index) ? "error-container" : ""
              }`}
            >
              {" "}
              <div className="inSideLink">
                <LinkOutlined className="Scrapig-Linkoutlined" />
                <Input
                  className="Shopping-Collage-Input"
                  type="url"
                  value={link.siteUrl}
                  onChange={(e) => handleLinkChange(index, "siteUrl", e)}
                  placeholder="Enter Site URL"
                />{" "}
              </div>
              <div className="inSideLink">
                <LinkOutlined className="Scrapig-Linkoutlined" />
                <Input
                  className="Shopping-Collage-Input"
                  type="url"
                  value={link.imageUrl}
                  onChange={(e) => handleLinkChange(index, "imageUrl", e)}
                  placeholder="Enter Image Address"
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="Shopping-Collage-Submit">
            <Button
              onClick={CreateCollageUseLink}
              disabled={loading || loadingBatch}
              className="button"
            >
              {loading ? <Spin size="small" /> : "Generate"}
            </Button>
          </div>
          <div className="underline-dotted"></div>
        </div>
        {sampleImages.length > 0 && (
          <p style={{ margin: "0% 2% 1% 2%" }}>
            Note: Choose the images below to add to your collage:
          </p>
        )}{" "}
        <div className="images-gallery">
          {sampleImages.map((product, index) => (
            <div
              key={index}
              style={{
                flex: "1",
                margin: "10px",
                cursor: "pointer",
                borderRadius: "8px",
                border: selectedImagesData.some(
                  (selectedItem) => selectedItem.imageURL === product.imageURL
                )
                  ? "2px solid blue"
                  : "2px solid #ddd3d3",
              }}
              onClick={() => toggleImageSelection(product)}
              className={`product-item ${
                selectedImages.includes(product.imageURL) ? "selected" : ""
              }`}
            >
              <CollageShoppingComponent
                brand={product.brand}
                imageURL={product.imageURL}
                name={product.name}
                price={product.price}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          ))}
        </div>
       
        <div className="images-gallery">{loadingBatch ? <Spin /> : <></>}</div>
       
      </div>
 
      <div className="Shopping-Collage-Submit">
        <Button
          type=""
          onClick={handleSubmit}
          className="button"
          disabled={loading}
        >
          {/* {loading ? <Spin size="small" /> : "Add"} */}
          Add
        </Button>
      </div>
    </>
  );
};
 
export default Links;
 
 