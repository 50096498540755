import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, notification } from "antd";
import config from "../config";
import "./css/Accessories.css";

const Accessories = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");

  // State to store accessories data, selected category, subcategory, selected items, and loading state
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch user accessories data
  const getUserAccessoriesData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${config.baseURL}getStylistCollages/${SelectUserData.gender}`
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        setAccessoriesData(fetchedData);

        // Automatically select the first category and subcategory
        const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
        const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
        setSelectedOutfitCategory(firstCategory);
        setSelectedSubcategory(firstSubcategory);

        // Filter data based on initial selection
        filterData(firstCategory, firstSubcategory, fetchedData);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load accessories data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch previously selected items
  const getPreviouslySelectedItems = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${SelectUserData.user_id}/1/${SelectUserData.SERVICE_SHORT_CODE}`
      );
      if (response?.data && response.data.length > 0) {
        const data = response.data[0];
        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );
        setSelectedItems(selectedImagesList);
      }
    } catch (error) {
      // notification.error({
      //   message: "Error",
      //   description: "Failed to load previously selected items.",
      // });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getUserAccessoriesData();
    getPreviouslySelectedItems();
  }, []);

  useEffect(() => {
    if (selectedOutfitCategory && selectedSubcategory) {
      filterData(selectedOutfitCategory, selectedSubcategory);
    }
  }, [selectedOutfitCategory, selectedSubcategory, AccessoriesData]);
  // Function to filter data based on category and subcategory
  const filterData = (category, subcategory, data = AccessoriesData) => {
    // console.log("Filtering data for:", category, subcategory);
    const filtered = data.filter(
      (item) =>
        item.OUTFIT_CATEGORY === category && item.OUTFIT_TEXT === subcategory
    );
    // console.log("Filtered data:", filtered); // Debugging statement
    setFilteredData(filtered);
  };

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedOutfitCategory(category);
    const firstSubcategoryInCategory = AccessoriesData.find(
      (item) => item.OUTFIT_CATEGORY === category
    )?.OUTFIT_TEXT;
    setSelectedSubcategory(firstSubcategoryInCategory);
  };

  // Handle subcategory selection
  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  // Handle item selection (multi-select)
  const handleItemSelect = (item) => {
    setSelectedItems((prevState) => {
      const exists = prevState.some(
        (selectedItem) => selectedItem.ID === item.ID
      );
      if (exists) {
        return prevState.filter((selectedItem) => selectedItem.ID !== item.ID);
      }
      return [...prevState, item];
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (selectedItems.length < 2) {
      notification.error({
        message: "Minimum",
        description: "Please select at least two images to upload.",
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      const uploadData = new FormData();
      uploadData.append("CUSTOMER_ID", SelectUserData.user_id);
      uploadData.append("STYLIST_ID", SelectUserData.stylist_id);
      uploadData.append("SERVICE_ID", SelectUserData.SERVICE_SHORT_CODE);
      uploadData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
      uploadData.append("LINK_LIST", JSON.stringify([]));

      selectedItems.forEach((image) => {
        uploadData.append("IMAGE_LIST", image.OUTFIT_IMAGE);
      });

      uploadData.append("SOURCE_CONTENT", JSON.stringify(selectedItems));
      uploadData.append("SELECTED_IMAGES_DATA", JSON.stringify(selectedItems));
      uploadData.append("type", "1");

      const uploadResponse = await axios.post(
        `${config.baseURL}websiteSourceContent`,
        uploadData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (uploadResponse.status === 200) {
        notification.success({
          message: "Added Images",
          description: `${selectedItems.length} Images have been added successfully.`,
        });
        getPreviouslySelectedItems(); // Refresh previously selected items
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Failed to upload images. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Upload Error",
        description: "There was an error uploading images.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    console.log("Filtered Data:", filteredData);
  }, [filteredData]);

  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  // Extract unique categories and subcategories
  const uniqueCategories = [
    ...new Set(AccessoriesData.map((item) => item.OUTFIT_CATEGORY)),
  ];

  const subcategoriesInSelectedCategory = [
    ...new Set(
      AccessoriesData.filter(
        (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
      ).map((item) => item.OUTFIT_TEXT)
    ),
  ];

  return (
    <>
      <div className="userProfileMain">
        <h1 className="heading">Accessories</h1>
      </div>
      <Spin spinning={loading}>
        <p style={{ margin: "13px 19px 0" }}>
          Note: Choose the images you would like to include in your collage.
        </p>
        <div className="accessories-container" style={{ width: "100%" }}>
          {/* Dynamic Category Tabs */}
          <div className="user-wardrobe scroll">
            {uniqueCategories.map((category, index) => (
              <button
                key={index}
                className={`button ${
                  selectedOutfitCategory === category ? "selected" : ""
                }`}
                onClick={() => handleCategorySelect(category)}
              >
                {capitalizeFirstLetter(category)}
              </button>
            ))}
          </div>

          {/* Dynamic Subcategory Tabs */}
          {subcategoriesInSelectedCategory.length > 0 && (
            <div className="user-wardrobe scroll">
              {subcategoriesInSelectedCategory.map((subcategory, index) => (
                <button
                  key={index}
                  className={`button ${
                    selectedSubcategory === subcategory ? "selected" : ""
                  }`}
                  onClick={() => handleSubcategorySelect(subcategory)}
                >
                  {capitalizeFirstLetter(subcategory)}
                </button>
              ))}
            </div>
          )}

          {/* Subcategory Images (Multiple Selection) */}
          {SelectUserData.status !== 4 && (
              <div className="submit-container" style={{width:'100%'}}>
                <button
                  className="button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {/* Submit */}
                  Add
                </button>
              </div>
            )}
          <div>
           
            <div className="subcategory-images">
              {/* {filteredData.length > 0 ? ( */}
              {filteredData.map((item,index) => (
                <div
                  key={index}
                  className={`subcategory-item ${
                    selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleItemSelect(item)}
                  style={{
                    border: selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "2px solid blue"
                      : "1px solid #ccc",
                  }}
                  onDragStart={(e) => {

                    e.dataTransfer.setData("application/json", item.OUTFIT_IMAGE);
                  }}
                >
                  <img
                    src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
                    alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
                  />
                  <p>{item.OUTFIT_TEXT}</p>
                </div>
              ))}
               {/* ) : (  <p>No items available for this selection.</p>
              )} */}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Accessories;
